/* eslint-disable no-mixed-spaces-and-tabs */
<template>
	<!-- 컨텐츠 START -->
	<div class="content_container main" v-if="!homeLoading">
		<!--메인배너 START-->
		<swiper class="main_banner" v-bind="bannerSwiperOption">
			<swiper-slide v-for="banner in banners" :key="banner.banner_pk" class="left">
				<div class="text_wrapper">
					<p class="title" v-bind:style="{ color: banner.color ? banner.color : '#222' }">{{ banner.title }}</p>
					<p class="text" v-bind:style="{ color: banner.color ? banner.color : '#222' }">
						{{ banner.content }}
					</p>
					<div class="btn_wrapper" v-if="banner.button_yn === 'true'">
						<button
							type="button"
							class="btn_common btn_mid btn_detail_slide"
							v-bind:style="{
								color: banner.color ? banner.color : '#222',
								border: banner.color ? '0.1rem solid ' + banner.color : '0.1rem solid #222',
							}"
							@click="onClickBannerDetail(banner.link)"
						>
							자세히 보기
						</button>
					</div>
				</div>
				<div class="img_wrapper">
					<picture>
						<source media="(max-width: 768px)" :srcset="banner.path + banner.m_save_name" />
						<img :src="banner.path + banner.save_name" alt="슬라이드 이미지" />
					</picture>
				</div>
			</swiper-slide>
		</swiper>

		<section class="common_section" v-if="displayProduct[0].product.length > 0">
			<TimedealProduct :products="displayProduct[0]" />
		</section>
		<section class="common_section" v-if="displayProduct[1].product.length > 0">
			<OnlyTodayProduct :products="displayProduct[1]" />
		</section>

		<div v-for="section in displayProduct.slice(2)" :key="section.display_pk">
			<HomeSectionTemplate
				:sectionTitle="section.content"
				:subContents="section.sub_content"
				:useSlideYn="section.slide_yn === 'true' ? true : false"
				:products="section.product.slice(0, 15)"
				:display_pk="section.display_pk"
				:sectionDivision="section.division"
			/>
		</div>

		<div>
			<section class="common_section">
				<SecondHeader title="리뷰" />
				<SectionDivider />
				<ReviewList :reviews="review" />
			</section>
		</div>
		<div>
			<section class="common_section">
				<SecondHeader title="기획전" />
				<SectionDivider />
				<section class="common_ing_wrap">
					<div class="common_ing border_top">
						<ul>
							<li v-for="ex in exhibition" :key="ex.exhibitions_pk">
								<ArticleComponent :article="ex" type="기획전" />
							</li>
						</ul>
					</div>
				</section>
			</section>
		</div>
	</div>

	<HomePopup v-if="false" />

	<button @click="onClickTop" class="top_btn" :class="topBtnShow">↑</button>
</template>

<script>
	import { ref, reactive, computed, onBeforeMount, onMounted, watch, onUpdated } from 'vue';
	import ProductItem from '@/components/molcules/Product/ProductItem.vue';
	import HomeSwiperSection from '@/components/organisms/home/HomeSwiperSection.vue';
	import HomeRanking from '@/components/organisms/home/HomeRanking.vue';
	import TimeDealItem from '@/components/molcules/Product/RowProductItem.vue';
	import HomeSectionTemplate from '@/components/organisms/home/HomeSectionTemplate.vue';
	import HomePopup from '../components/organisms/home/HomePopup.vue';

	import SwiperCore, { Pagination, Autoplay } from 'swiper';
	import { Swiper, SwiperSlide } from 'swiper/vue';
	import 'swiper/swiper-bundle.min.css';
	import { numberFormat } from '@/utils/numberFormat';

	import { useQuery } from 'vue-query';
	import { fetchHome } from '@/api/index';
	import { useStore } from 'vuex';
	import { useRoute } from 'vue-router';
	import router from '../router';

	import useAxios from '../compositions/useAxios';
	import ReviewList from '../components/organisms/home/item/ReviewList.vue';
	import SecondHeader from '../components/molcules/Text/SecondHeader.vue';
	import SectionDivider from '../components/molcules/Divider/SectionDivider.vue';
	import ArticleComponent from '../components/molcules/Article/ArticleComponent.vue';
	import Magazine from './Categories/Magazine/Magazine.vue';
	import TimedealProduct from '../components/organisms/home/item/TimedealProduct.vue';
	import OnlyTodayProduct from '../components/organisms/home/item/OnlyTodayProduct.vue';

	SwiperCore.use([Pagination, Autoplay]);

	export default {
		name: 'Home',
		components: {
			Swiper,
			SwiperSlide,
			ProductItem,
			HomeSwiperSection,
			HomeRanking,
			TimeDealItem,
			HomeSectionTemplate,
			ReviewList,
			SecondHeader,
			SectionDivider,
			ArticleComponent,
			Magazine,
			TimedealProduct,
			OnlyTodayProduct,
			HomePopup,
		},
		mixins: [numberFormat],
		emits: ['home-loading'],
		setup(props, { emit }) {
			onMounted(() => {
				window.scrollTo({ top: 0, left: 0 });
			});

			return {
				...useTimer(),
				...useHome(emit),
				...useSocialToken(),
				...useScrollToTop(),
			};
		},
	};
	const useHome = (emit) => {
		const { isLoading, data } = useQuery('home', fetchHome, {
			staleTime: 60 * 1000, // 60초 동안 fresh한 상태로 caching
			cacheTime: 60 * 1000,
		});

		const banners = computed(() => (data.value ? data.value?.banner.filter((v) => v.use_yn === 'true') : []));
		const onClickBannerDetail = (link) => {
			location.href = link;
		};
		const display = computed(() => (data.value ? data.value?.display.filter((d) => d.use_yn === 'true') : []));
		const timeDealProduct = computed(() =>
			display.value ? display.value.find((v) => v.division === '핫딜')?.product : [],
		);
		//const rankingProduct = computed(() => (display.value ? display.value.find((v) => v.division === '랭킹') : []));
		//const basicProduct = computed(() => (display.value ? display.value.filter((v) => v.division === '일반') : []));
		const magazine = computed(() => data.value.magazine);
		const review = computed(() => data.value.review);
		const exhibition = computed(() => data.value.exhibitions);
		const bannerSwiperOption = ref({
			speed: 400,
			loop: true,
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
			},
			pagination: {
				clickable: true,
			},
		});

		const timeDealSwiperOption = computed(() => {
			return {
				speed: 500,
				loop: timeDealProduct.value.length > 3 ? true : false,
				autoplay:
					timeDealProduct.value.length > 3
						? {
								delay: 5000,
								disableOnInteraction: false,
								// eslint-disable-next-line no-mixed-spaces-and-tabs
						  }
						: false,
				navigation: true,
				pagination: {
					clickable: true,
				},
				breakpoints: {
					899: {
						slidesPerView: 1,
						slidesPerGroup: timeDealProduct.value.length >= 2 ? 2 : timeDealProduct.value.length,
						spaceBetween: 10,
					},
					930: {
						slidesPerView: timeDealProduct.value.length >= 2 ? 2 : timeDealProduct.value.length,
						slidesPerGroup: timeDealProduct.value.length >= 2 ? 2 : timeDealProduct.value.length,
						spaceBetween: 10,
					},
					1130: {
						slidesPerView: timeDealProduct.value.length >= 3 ? 3 : timeDealProduct.value.length,
						slidesPerGroup: timeDealProduct.value.length >= 3 ? 3 : timeDealProduct.value.length,
						spaceBetween: 10,
					},
				},
			};
		});

		const displaySection = computed(() => {
			const result = display.value.map((item) => {
				if (item.use_yn === 'true') {
					return item;
				}
			});

			//fillEmptyTemplate('매거진', result);
			//fillEmptyTemplate('리뷰', result);
			//fillEmptyTemplate('기획전', result);
			//console.log(result);
			return result;
		});

		//const fillEmptyTemplate = (topic, result) => {
		//	const contents = result.map((item) => item.content);
		//	if (!contents.includes(topic)) {
		//		result.push({
		//			content: topic,
		//			product: [],
		//		});
		//	}
		//};
		watch(isLoading, () => {
			emit('home-loading');
		});

		return {
			homeLoading: isLoading,
			banners,
			bannerSwiperOption,
			displayProduct: displaySection,
			timeDealSwiperOption,
			timeDealProduct,
			onClickBannerDetail,
			//rankingProduct,
			//basicProduct,
			review,
			magazine,
			exhibition,
		};
	};

	const useTimer = () => {
		const store = useStore();
		const endTime = computed(() => new Date(store.getters['template/timedealEndDate']));

		//const isTimedealEnded = computed(() => (new Date(endTime.value) > new Date() ? false : true));
		const isTimedealEnded = computed(() => (new Date(endTime.value) > new Date() ? false : false));

		const remainTime = ref('');
		const remainDay = ref(0);
		const remainHour = ref(0);
		const remainMinute = ref(0);
		const remainSecond = ref(0);
		const timer = ref(null);

		onMounted(() => {
			if (endTime.value) startCounter();
		});
		//onBeforeMount(() => {
		//	store.commit('template/setTimeDealRemainTime', {
		//		remainDay: remainDay.value,
		//		remainHour: remainHour.value,
		//		remainMinute: remainMinute.value,
		//		remainSecond: remainSecond.value,
		//	});
		//	clearInterval(timer.value);
		//});
		function startCounter() {
			let days, hours, minutes, seconds;

			timer.value = setInterval(() => {
				const startTime = new Date();
				const diffTime = parseInt((new Date(endTime.value).getTime() - startTime.getTime()) / 1000, 10); //초
				days = parseInt(diffTime / (3600 * 24), 10);
				hours = parseInt((diffTime / 3600) % 24, 10);
				minutes = parseInt((diffTime / 60) % 60, 10);
				seconds = parseInt(diffTime % 60, 10);
				remainDay.value = days.toString();
				remainHour.value = hours.toString();
				remainMinute.value = minutes < 10 ? '0' + minutes.toString() : minutes.toString();
				remainSecond.value = seconds < 10 ? '0' + seconds.toString() : seconds.toString();
				remainTime.value = days + '일 ' + hours + '시간 ' + minutes + '분 ' + seconds + '초';
				store.commit('template/setTimeDealRemainTime', {
					remainDay: remainDay.value,
					remainHour: remainHour.value,
					remainMinute: remainMinute.value,
					remainSecond: remainSecond.value,
				});

				if (new Date() > new Date(endTime.value)) {
					clearInterval(timer.value);
					store.commit('template/setIsTimedealEnd');
				}
			}, 1000);
		}
		return {
			remainTime,
			remainDay,
			remainHour,
			remainMinute,
			remainSecond,
			isTimedealEnded,
		};
	};

	const useSocialToken = () => {
		const route = useRoute();
		const store = useStore();
		//const { postData } = useAxios();
		watch(
			route,
			() => {
				if (route.query?.t) {
					store.dispatch('user/SOCIAL_LOGIN', route.query.t).then(() => {
						//router.push('/').then(() => {
						//	router.go(0);
						//});
					});
				}
			},
			{
				deep: true,
				immediate: true,
			},
		);
	};
	const useScrollToTop = () => {
		const topBtnShow = ref('');
		const topBtnHandler = () => {
			if (window.scrollY < 100) {
				topBtnShow.value = 'top_hide';
			} else {
				topBtnShow.value = '';
			}
		};
		onMounted(() => {
			window.addEventListener('scroll', topBtnHandler);
		});
		const onClickTop = () => {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		};
		return {
			onClickTop,
			topBtnShow,
		};
	};
</script>

<style scoped src="@/assets/css/home.css"></style>
<style scoped>
	.monthly_brand .item_list_container ul.item_list {
		padding: 0rem 2rem;
		display: flex;
		justify-content: center;
	}
	.monthly_brand .item_list_container .item_list #brand_product {
		width: 233.5px;
		height: 302.5px;
		margin: 0rem 2rem;
	}
	.timedeal_item_wrapper {
		height: 18rem;
	}
	@media screen and (max-width: 768px) {
		.timedeal_item_wrapper {
			width: 100%;
		}
		/* .home-popup {
			width: 400px;
			width: 100vw;
			height: 400px;
		} */
	}
	/* 메인 페이지에서만 위치 조금 아래로 내림 */
	@media screen and (max-width: 930px) {
		.top_btn {
			bottom: 9rem;
		}
	}
</style>
